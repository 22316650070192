// General
b, strong {
    font-weight: 600;
}
.bg-gray{
    background-color: #F8F8F8;
}
.reading-time-text {
    font-size: 1.2em;
    opacity: 0.7;
}
.nowrap{
    flex-wrap: nowrap !important;
}
.react-cookie-banner{
  position: fixed!important;
  background-color: #001838!important;
  top: calc(100vh - 45px);
}

// Animations
.growth {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    &:hover{
        transform: scale(1.1);
    }
}

// Pages
#headers {
    .content{
        margin-top: none;
        text-align: center;
        margin-bottom: 50px;
        width: 100%;
    }
}

.subscribe-line-white{
    background-position: center;
    background-size: cover;
}

.subscribe-line{
    background-color: #001838;
    color: white;
    margin: 0 0 30px 0;
    .input-group{
        margin-bottom: 15px;
    }
    .card {
        margin-top: 15px;
    }
    .content-styles{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .title{
        padding: 0;
        margin: 0;
    }
    .description{
        color: white;
    }
}

.supportus-line{
    color: #2c2c2c;
    margin: 30px 0;
    .container{
        padding: 30px 15px;
    }
    .input-group{
        margin-bottom: 15px;
    }
    .card {
        margin-top: 15px;
    }
    .content-styles{
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
    .title{
        padding: 0;
        margin: 0;
        margin-bottom: 30px;
    }
    .brand-wrapper{
        display: flex;
        justify-content: center;
        align-items: center;
    }
    img{
        margin: 15px 0;
        filter: grayscale(100%);
        opacity: 0.7;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        &:hover{
            filter: grayscale(0%);
            transform: scale(1.1);
        }
    }
}

.magazine-container{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    img{
        max-width: 260px;
        margin-bottom: 15px;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
        &:hover{
            filter: grayscale(0%);
            transform: scale(1.4);
        }
    }
    .description {
        max-width: 300px;
        text-align: center;
    }
}

// Preloader
#preloader{
    background-color: #001838;
}

// Navbar
.navbar{
    background-color: #FFFFFF !important;
    flex-direction: column;
    padding: 0;

    .nav-link {
        line-height: 1.9rem!important;
        p {
        font-size: 16px!important;
        text-transform: capitalize!important;
        font-weight: 600;
        }
    }
    .a-link.nav-link{
        font-size: 16px!important;
        text-transform: capitalize!important;
        font-weight: 600;
    }
    .dropdown-item{
        font-size: 16px!important;
        text-transform: capitalize!important;
    }
    .socialnetwork-items{
        i {
            font-size: 20px!important;
        }
    }
    .mobile-search {
        display: none;
    }
}
@media screen and (max-width: 768px){
    .navbar {
        padding: 0 15px;
    }
}
@media screen and (max-width: 992px){
    .navbar {
        .mobile-search {
            display: block;
        }
    }
    .react-cookie-banner{
      position: fixed!important;
      height: 90px!important;
      display: flex!important;
      background-color: #001838!important;
      top: calc(100vh - 90px)!important;
      .cookie-message{
        line-height: normal!important;
        text-align: left!important;
        padding: 1rem!important;
      }
      .button-close{
        left: 1rem!important;
        margin-top: 10px!important;
      }
    }
}
.page-header-image {
    background-position: center 50px !important;
}
.smaller-header{
    .navbar-top-styles {
        min-height: 53px;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }
    .navbar-brand {
        img {
            max-width: 230px;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            -ms-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;
        }
    }
}

.navbar-top-styles{
    min-height: 74px;
}
.navbar-options-styles{
    width: 100%;
    background-color: #001838;
    a.nav-link{
        color: #FFFFFF !important;
    }
    .navbar-nav{
        margin-left: initial !important;
    }
}
.navbar.bg-white:not(.navbar-transparent) a:not(.dropdown-item):not(.btn) {
    color: #001838;
}
.navbar-translate {
    .img{
        max-width: 320px;
        -webkit-transition: all 0.3s;
        -moz-transition: all 0.3s;
        -ms-transition: all 0.3s;
        -o-transition: all 0.3s;
        transition: all 0.3s;
    }
    @media screen and (max-width: 768px){
        .img {
            max-width: 250px;
        }
    }
}
#ceva{
    display: flex;
    align-items: center;
}

// Navbar mobile
.sidebar-collapse .navbar-collapse:before {
    background-color: #001838 !important;
    .nav-item a {
        color: white !important;
    }
}
.dropdown.show .dropdown-menu,
.dropdown .dropdown-menu{
    overflow-y: unset !important;
    height: unset !important;
}

// Footer
.footer {
    background-position: bottom;
    background-size: cover;
    .footer-logo{
        max-width: 250px;
        padding: 0px;
        opacity: 1;
    }
    .tip-logo{
        max-width: 80px;
        opacity: 1;
    }
    .go-top-image{
        margin-bottom: 30px;
        display: flex;
        justify-content: right;
    }
    p{
        font-weight: 300;
        font-size: 1.1em;
    }
    .jc-center {
        justify-content: center;
    }
    .ai-center {
        align-items: center;
    }
    .left-border{
        border-left: 1px solid rgba(0,0,0,.1);
    }
    @media screen and (max-width: 768px){
        .left-border{
            border-left: none;
        }
        .jc-center {
            justify-content: initial;
        }
        .tip-block{
            margin-top:30px;
            flex-wrap: nowrap;
        }
        .p-block{
            flex-wrap: nowrap;
        }
    }
}

@media screen and (min-width: 992px){
    .footer:not(.footer-big) .copyright {
        float: initial;
    }
}

// Header
.carousel .carousel-inner {
    box-shadow: none;
}
.page-header > .content {
    margin-top: 120px !important;
}
.now-ui-icons.arrows-1_minimal-right:before, .now-ui-icons.arrows-1_minimal-left:before {
    color: #001838!important;
}
.carousel-indicators li{
    background-color: #000!important;
}
.hero-placeholder{
    height: 600px;

}
.page-header{
    display: flex;
    justify-content: center;
    align-items: center;
    .hero {
        background-position: right;
        width: 60%;
        right: -10%;
        background-position-x: right;
    }
    .title{
        padding-top: 0px;
        font-size: 2.2rem;
        text-align: left;
        color: #001838!important;
    }
    @media screen and (max-width: 768px) {
        .title{
            font-size: 1.6rem;
        }
        .description {
            font-size: 1rem;
        }
    }
    .description {
        font-weight: 400;
        text-align: left;
        color: #001838;
    }
    .category{
        font-size: 14px;
        font-weight: 600;
        color: #001838;
    }
    .btn-round:hover {
        background-color: #001838;
        color:white;
    }
    .magazine-img{
        max-width: 250px;
        height: auto;
    }
    .mobile-breadcrum-custom{
        display:none;
        .breadcrumb {
            background-color: transparent;
            padding: .75rem 0;
            a {
                font-size: 18px;
                text-decoration: none;
                color: #001838;
            }
        }
    }
    @media screen and (max-width: 768px) {
         .breadcrum-custom{
            display:none;
        }
        .mobile-breadcrum-custom{
            display:block;
        }
    }

    .breadcrum-custom .breadcrumb{
        background-color: transparent;
        padding: .75rem 0;
        a {
            text-decoration: none;
            color: #001838;
        }
        .active {
            color: #001838;
            font-weight: bolder;
        }
    }
    &:before{
        background: rgb(255,255,255)!important;
        background: linear-gradient(90deg, rgba(255,255,255,1) 50%, rgba(255,255,255,0.9304096638655462) 53%, rgba(0,212,255,0) 100%)!important;
    }
}
.page-header-static{
    &:before{
        background: rgb(255,255,255)!important;
        background: linear-gradient(90deg, rgba(255,255,255,1) 15%, rgba(255,255,255,0.9304096638655462) 50%, rgba(0,212,255,0) 100%)!important;
    }
}
@media screen and (max-width: 768px){
    .page-header{
        .hero {
            background-position: center;
            width: 100%;
            right: 0;
            background-position-x: center;
        }
        &:before{
            background: rgb(255,255,255)!important;
            background: linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0.9304096638655462) 53%, rgba(255,255,255,0.7) 100%)!important;
        }
        .magazine-img{
            display: none;
        }
    }
}
.page-header:before {
    background-color: rgba(0, 0, 0, 0.45);
}
.page-header .content-center {
    top: 58%;
    max-width: inherit;
}
.page-header-content {
    margin-top: 44px;
    .title {
        text-transform: capitalize;
    }
}

// Cards (smart & global citites)
.landing-page-card{
    box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.3);
    opacity: 0.7;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    &:hover{
      opacity: 1;
    }
    .title{
        color: #001838!important;
        padding-top: 15px;
    }
    .description{
        color: #001838!important;
        font-size: 20px;
    }
    .custom-icon{
        color: #001838!important;
        font-size: 55px;
    }
    .list-group{
        background: transparent;
    }
    .list-group-item{
        background: transparent;
        border: none;
        a, i{
            color: #001838!important;
            text-decoration: none;
            font-size: 120%;
            border-bottom: none!important;
        }
    }
}
.card-fashion:not(.card-background):before {
    display: none;
}
.card-fashion{
    min-height: 620px;
    button:hover{
        background-color: #001838;
        color: white;
        i{
            color: white!important;
        }
    }
}

// Cities

.section-cities{
    .nav-pills-info{
        justify-content: center;
        margin-bottom: 30px;
    }
    .nav-link{
        padding: 40px 80px !important;
        i {
            font-size: 30px!important;
        }
    }

}

// News (Landing)
.section-about-us {
    background-color: #F8F8F8;
}
.country{
    display: flex;
}
.profile-photo{
    border: 2px solid #8888888a;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    background-position: center;
    background-size: cover;
    display: block;
    width: 100%;
    padding-top: 100%;
    &:hover{
        transform: scale(1.3);
    }
}
@media screen and (max-width: 470px){
    .card-profile{
        .profile-photo{
            width: 60%;
            padding-top: 60%;
            margin: 0 auto;
            &:hover{
                transform: scale(1);
            }
        }
        .card-body{
            text-align: center;
        }
        .country{
            margin: 0 auto;
            display: block;
        }
        .card-footer{
            text-align: center!important;
        }
    }
}

// Legal
.legal-styles {
    padding-top: 80px;
}

// Tools
.tools-custom-styles{
    .tool-img{
        background-position: center;
        background-size: cover;
        border: 2px solid #8888888a;
        display: block;
        margin: 0 auto;
        padding-top: 60%;
        position: relative;
        width: 60%;
    }
}

// Articles
.article-wrapper{
    display: flex;
    align-items: center;
    justify-content: center;
}
.article-cover{
    background-position: center;
    background-size: cover;
    display: block;
    height: 200px;
    width: 100%;
    filter: brightness(0.2);
}
.article-img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    height: 200px;
    width: 100%;
    position: absolute;
    top: 0;
}
.video-styles{
    width: 100%;
}

// Books
.books-img {
    background-image: url(https://images.clarin.com/2020/01/15/el-motociclista-argentino-mauricio-gomez___YpYRUDRN_0x750__3.jpg);
    background-position: center;
    background-size: cover;
    display: block;
    height: 220px;
    width: 100%;
}
.book-flag{
    max-width: 42px;
}
.book-lang{
    position: absolute;
    top: 5px;
    right: 5px;
    box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.3);
}
.book-title{
    font-weight: 600;
    font-size: 1rem
}
@media screen and (max-width: 768px) and (min-width:  470px){
    .books-img {
        height: 330px;
    }
}
@media screen and (max-width: 470px){
    .books-img {
        height: 270px;
    }
}


// Article
.article-styles {
    .title{
        margin-bottom: 0px;
    }
    .subtitle{
        margin-top: 15px;
        margin-bottom: 30px;
    }
    .image-description {
        font-size: 14px;
        font-weight: 500;
        margin-bottom: 30px;
        margin-top: 15px;
    }
    p {
        white-space:pre-wrap;
    }
}

// Revista
.magazine-styles{
    .section-description{
        margin-bottom: 60px;
    }
    .card-description{
        font-size: 14px;
    }
}
.magazine_image-section{
    display: flex;
    align-items: center;
    justify-content: center;
}
.magazine-img{
    background-image: url(https://images.clarin.com/2020/01/15/el-motociclista-argentino-mauricio-gomez___YpYRUDRN_0x750__3.jpg);
    background-position: center;
    background-size: cover;
    display: block;
    height: 380px;
    width: 100%;
}
@media screen and (max-width: 768px) and (min-width: 580px){
    .magazine-img {
        height: 330px;
    }
}
@media screen and (max-width: 580px) and (min-width: 470px){
    .magazine-img {
        height: 600px;
    }
}
@media screen and (max-width: 470px){
    .magazine-img {
        height: 470px;
    }
}

// Responsive

@media screen and (max-width: 991px){
    .sidebar-collapse .navbar-collapse:before {
        background: #FFF;
    }
    .nav-item a{
        color: black;
    }
    .navbar-collapse{
       background-color: #001838;
    }
}
@media screen and (max-width: 470px){
    .section-cities, .section-social-networks {
        display: none;
    }
}



// Mailchimp
.newsletter-styles-custom{
    background-color: white;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
        -webkit-transition: "color 9999s ease-out, background-color 9999s ease-out";
        -webkit-transition-delay: 9999s;
    }
}
.custom-mailchimp-form{
    input{
        background-color: transparent;
        border-radius: 30px;
        border: 1px solid #E3E3E3;
        box-shadow: none;
        color: #E3E3E3;
        font-size: 0.8571em;
        height: auto;
        line-height: normal;
        margin-right: 2%;
        min-width: 50%;
        padding: 10px 18px 10px 18px;
        transition: color 0.3s ease-in-out, border-color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    }
    button{
        background-color: #001838;
        border-radius: 30px !important;
        border-width: 1px;
        color: #FFFFFF;
        border-width: 1px;
        border-radius: 30px !important;
        padding: 11px 23px;
        margin: 2%;
    }
}

// Redes sociales
.section-social-networks{
    background-color: #F8F8F8;
    .title {
        font-size: 160%;
    }
    .twitter-wrapper{
        height: 570px;
        overflow-y: auto;
    }
}
.video-container {
    position: relative;
    width: 100%;
    height: 0;
    padding-bottom: 56.25%;
}
.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

// Search
.card-search-custom{
    min-height: 200px;
}
.search-img {
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    display: block;
    height: 200px;
    width: 100%;
    position: relative;
    top: 0;
}

// sobre nosotros
.mision-styles{
    img {
        opacity: 0.9;
    }
}
.info p {
    font-size: 1.4em;
}
